import type {UrlObject} from "url";

import memoizee from "memoizee";
import {useRouter} from "next/router";
import {v4} from "uuid";

import {GetSchedulingRedirectUrlParams} from "../../../components/_common/types";
import {SpecialtyId} from "../../../constants/specialtyIds";
import {getSchedulingRedirectUrl} from "../../../utils/getRedirectUrl";
import {ignoreArrays} from "../../../utils/ignoreArrays";
import {removeFalsyKeys} from "../../../utils/removeFalsyKeys";

type QueryValue = string | string[] | undefined;

type BookingQuery = {
  "appointment-reason"?: QueryValue;
  "appointment-id"?: QueryValue;
  "initial-calendar-time"?: QueryValue;
  canceled?: QueryValue;
  canceling?: QueryValue;
  quitting?: QueryValue;
  location?: QueryValue;
  email?: QueryValue;
  time?: QueryValue;
  slotIds?: QueryValue;
  slotUnavailable?: QueryValue;
  rescheduling?: QueryValue;
  originUrl?: QueryValue;
  flowId?: QueryValue;
  patientId?: QueryValue;
  token?: QueryValue;
};

type ParsedBookingQuery = {
  appointmentReasonSlug: string | null;
  appointmentId: string | null;
  canceled: boolean | null;
  canceling: boolean | null;
  quitting: boolean | null;
  locationSlug: string | null;
  selectedTime: number | null;
  initialCalendarTime: number | null;
  selectedSlotIds: string[] | null;
  isSelectedTimeUnavailable: boolean;
  rescheduling: boolean | null;
  originUrl: string | null;
  flowId: string | null;
  patientId: string | null;
  token: string | null;
};

export const parseBookingQuery = memoizee(
  (query: BookingQuery): ParsedBookingQuery => {
    const selectedTime = ignoreArrays(query.time);
    const originUrl = ignoreArrays(query.originUrl);
    const slotIds = ignoreArrays(query.slotIds);
    const initialCalendarTime = ignoreArrays(query["initial-calendar-time"]);
    return {
      appointmentReasonSlug: ignoreArrays(query["appointment-reason"]),
      appointmentId: ignoreArrays(query["appointment-id"]),
      canceled: safeBoolParse(query["canceled"]),
      canceling: safeBoolParse(query["canceling"]),
      quitting: safeBoolParse(query["quitting"]),
      locationSlug: ignoreArrays(query.location),
      selectedTime: selectedTime ? parseInt(selectedTime) : null,
      initialCalendarTime: initialCalendarTime ? parseInt(initialCalendarTime) : null,
      selectedSlotIds: slotIds ? JSON.parse(decodeURIComponent(slotIds)) : null,
      isSelectedTimeUnavailable: ignoreArrays(query.slotUnavailable) === "true",
      rescheduling: ignoreArrays(query.rescheduling) === "true",
      originUrl: originUrl ? decodeURIComponent(originUrl) : null,
      flowId: ignoreArrays(query.flowId),
      patientId: ignoreArrays(query.patientId),
      token: ignoreArrays(query.token),
    };
  },
  {
    normalizer: JSON.stringify,
  },
);

export const useParsedBookingQuery = () => {
  const router = useRouter();
  return parseBookingQuery(router.query);
};

const falsifyBadStrings = (val: unknown) => {
  const string = `${val}`;
  return string === "undefined" ? undefined : string === "null" ? null : string;
};

export const buildBookingLink = memoizee(
  (query: Partial<ParsedBookingQuery>) => ({
    pathname: "/booking",
    query: removeFalsyKeys({
      "appointment-reason": query.appointmentReasonSlug || null,
      "appointment-id": query.appointmentId || null,
      "initial-calendar-time": falsifyBadStrings(query.initialCalendarTime) || null,
      canceled: falsifyBadStrings(query.canceled) || null,
      canceling: falsifyBadStrings(query.canceling) || null,
      quitting: falsifyBadStrings(query.quitting) || null,
      location: query.locationSlug || null,
      flowId: query.flowId || null,
      slotIds: query.selectedSlotIds
        ? encodeURIComponent(JSON.stringify(query.selectedSlotIds))
        : null,
      time: falsifyBadStrings(query.selectedTime) || null,
      slotUnavailable: falsifyBadStrings(query.isSelectedTimeUnavailable) || null,
      rescheduling: falsifyBadStrings(query.rescheduling) || null,
      originUrl: query.originUrl || null,
      patientId: query.patientId || null,
      token: query.token || null,
    }),
  }),
  {
    normalizer: JSON.stringify,
  },
);

export const startBookingFlow = (query: Partial<ParsedBookingQuery>): [UrlObject, string] => {
  const flowId = v4();
  return [buildBookingLink({...query, flowId}), flowId];
};

export const useCurrentFlowId = () => {
  const {query} = useRouter();
  return ignoreArrays(query.flowId);
};

const safeBoolParse = (param?: string | string[]) => {
  if (param === "true") {
    return true;
  } else if (param === "false") {
    return false;
  }
  return null;
};

export const getSchedulingLink = ({
  webSchedulingQuery,
  specialtyIds,
  patientAppSchedulingQuery,
}: {
  specialtyIds: string[];
  webSchedulingQuery: Partial<ParsedBookingQuery>;
  patientAppSchedulingQuery: GetSchedulingRedirectUrlParams;
}) => {
  const WEB_SCHEDULING_ENABLED = specialtyIds.includes(SpecialtyId.URGENT_CARE);
  const [webSchedulingLink, flowId] = startBookingFlow(webSchedulingQuery);

  const bookingLink = WEB_SCHEDULING_ENABLED
    ? webSchedulingLink
    : getSchedulingRedirectUrl({...patientAppSchedulingQuery, flowId});

  return {bookingLink, flowId, WEB_SCHEDULING_ENABLED};
};
